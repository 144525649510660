import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/AuthComponent.css'; // Make sure to create a corresponding CSS file
import axios from 'axios';

const AuthComponent = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        axios.post('https://clf40sww7000008laey0whwq7.xyz:3001/user/login', {
            username,
            password
        })
            .then((response) => {
                console.log('Response:', response.data);
                
                localStorage.setItem('token', response.data);
                navigate('/sheets');
            })
            .catch((error) => {
                console.error('Error:', error.response);
            });
    };


    return (
        <div className="auth-component">
            <div className="login-container">
                <div className="login-header">
                    <h1>Login</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="input-container">
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="button-container">
                        <button type="submit">Login</button>
                    </div>
                </form>
            </div>
        </div>
    );
    
};

export default AuthComponent;
