import React from 'react';
import { jwtDecode } from 'jwt-decode'; 
import './css/Header.css'; // Make sure to create a corresponding CSS file
const Header = () => {
  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from local storage
    window.location.href = '/login'; // Redirect to login page
  };

  // Function to decode the token and get the username
  const getUsernameFromToken = () => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token); // Decode the token
      return decodedToken.username; // Return the username field
    }
    return '';
  };

  return (
    <header className="header">
      <div className="title">Sheets v0.5.1</div>
      <div className="user-info">
        <span className="username">{getUsernameFromToken()}</span>
        <button onClick={handleLogout} className="logout-button">Logout</button>
      </div>
    </header>
  );
};

export default Header;
