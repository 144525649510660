import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Login from './Pages/Login';
import Sheets from './Pages/Sheets';
import Sheet from './Components/Sheet';
import PrivateRoute from './Components/PrivateRoute';
import Header from './Components/Header';

const App = () => {
  return (
    <Router>
      <div className="App">
        <AppRoutes />
      </div>
    </Router>
  );
};

// This component has access to the routing context
const AppRoutes = () => {
  const location = useLocation();

  const shouldDisplayHeader = location.pathname !== '/login';

  return (
    <>
      {shouldDisplayHeader && <Header />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/sheets"
          element={
            <PrivateRoute>
              <Sheets />
            </PrivateRoute>
          }
        />
        <Route
          path="/sheet/:id"
          element={
            <PrivateRoute>
              <Sheet />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

export default App;
