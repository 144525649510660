import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import Handsontable from 'handsontable/base';
import { registerAllModules } from 'handsontable/registry';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './css/Sheet.css';
import CryptoJS from 'crypto-js';

registerAllModules();

const generateData = (rowCount, colCount) => {
    return Array.from({ length: rowCount }, () => new Array(colCount).fill(''));
};

function generateColumnHeaders(maxColumns) {
    const columns = [];
    let currentChar = 'A'.charCodeAt(0);
    let nextChar = 'A'.charCodeAt(0);

    for (let i = 0; i < maxColumns; i++) {
        let column = '';
        if (i > 25) { // Если индекс больше 25, начинаем добавлять двойные буквы
            column += String.fromCharCode(currentChar);
        }

        column += String.fromCharCode(nextChar);
        columns.push(column);

        // Увеличиваем следующий символ
        nextChar++;
        if (nextChar > 'Z'.charCodeAt(0)) {
            nextChar = 'A'.charCodeAt(0);
            currentChar++;

            if (currentChar > 'Z'.charCodeAt(0)) {
                break; // Завершаем цикл, если превысили ZZ
            }
        }
    }

    return columns;
}

const Sheets = (props) => {
    const { id } = useParams();
    const hotRef = useRef(null)
    const [activeSheet, setActiveSheet] = useState(0);
    const [isAutosave, setIsAutosave] = useState(true);
    const [hot, setHot] = useState(null);
    const [data, setData] = useState(null);
    const [columnHeaders, setColumnHeaders] = useState(generateColumnHeaders(50));

 
    const [loading, setLoading] = useState(true);
    const [sheetName, setSheetName] = useState(null);
    const [sheetDescription, setSheetDescription] = useState(null);

    const navigate = useNavigate();

    const goBack = () => {
        navigate('/sheets');
    };
    useEffect(() => {
        if (hot && data) {
            hot.loadData(data);
        }
    }, [data, hot]);

    useEffect(() => {
        const fetchSheetData = async () => {
            try {
                console.log(id)

                const response = await axios.get(`https://clf40sww7000008laey0whwq7.xyz:3001/sheets/sheet/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setData(response.data.data);
                setSheetDescription(response.data.description);
                setSheetName(response.data.name);
                setActiveSheet(id);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching sheet data:', error);
            }
        };

        fetchSheetData();
    }, [id]);

    const updateData = async (data) => {
        try {
            await axios.put(`https://clf40sww7000008laey0whwq7.xyz:3001/sheets/sheet/${id}`, {
                data
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((response) => {
                console.log(response);
            }).catch((error) => {
                console.error('Error:', error.response);
            });
        } catch (error) {
            console.error('Error updating sheet data:', error);
        }
    }

    // useEffect(() => {
    //     if (props.activeSheet !== activeSheet) {
    //         setActiveSheet(props.activeSheet);
    //     }
    //     console.log('handle sheet1 ')
    //     if (props.activeSheet == 0) return;
    //     var data = JSON.parse(localStorage.getItem('data'));
    //     if (data) {
    //         console.log('handle sheet2 ')
    //         var sheetData = data.find(x => x.activeSheet == props.activeSheet);
    //         console.log(sheetData)
    //         if (sheetData) {
    //             setData(sheetData.sheetData);
    //             if (hot) {
    //                 hot.loadData(sheetData.sheetData);
    //             }
    //         }
    //         else {
    //             console.log('handle shee3 ')
    //             setData(generateData(500, 26));
    //             setColumnHeaders(generateColumnHeaders(26));
    //         }
    //     }
    //     else {
    //         setData(generateData(500, 26));
    //         setColumnHeaders(generateColumnHeaders(26));
    //         console.log('handle sheet')
    //     }

    // }, [props.activeSheet]);


    const handleAfterChange = (change, source) => {
        if (activeSheet == 0) return;
        if (source === 'loadData' || !change) {
            return;
        }

        if (!isAutosave) {
            return;
        }

        const updatedData = [...data];
        change.forEach(([row, col, oldVal, newVal]) => {
            if (updatedData[row]) {
                updatedData[row][col] = newVal;
            }
        });

        setData(updatedData);
        updateData(change);
        // var existData = JSON.parse(localStorage.getItem('data'));
        // if (existData) {
        //     var sheetData = existData.find(x => x.activeSheet == activeSheet);
        //     if (sheetData) {
        //         sheetData.sheetData = updatedData;
        //         localStorage.setItem('data', JSON.stringify(existData));
        //     }
        //     else {
        //         existData.push({
        //             "sheetData": updatedData,
        //             "activeSheet": activeSheet,
        //         })
        //         localStorage.setItem('data', JSON.stringify(existData));
        //     }
        // }
        // else {

        //     var sheetData = [{
        //         "sheetData": updatedData,
        //         "activeSheet": activeSheet,
        //     }]
        //     localStorage.setItem('data', JSON.stringify(sheetData));
        // }
    };

    return (
        <div className="sheets">
            {loading ? (
                <div className="loading">
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                </div>
            ) : (
                <>
                    <div className='add-sheet-container'>
                        <button className="sheet-button" onClick={goBack}>Back</button>
                    </div>
                    <div className='table-center-container'>
                        <div className='hot-table'>
                            <h3>Active Sheet Id: {activeSheet}</h3>
                            <h3>Sheet Name: {sheetName}</h3>
                            <h3>Sheet Description: {sheetDescription}</h3>
                            <HotTable
                                ref={hotRef}
                                data={data}
                                rowHeaders={true}
                                colHeaders={columnHeaders}
                                width="100%"
                                height="auto"
                                licenseKey="non-commercial-and-evaluation"
                                afterChange={handleAfterChange}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );

};

export default Sheets;
