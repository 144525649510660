import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const verifyToken = async () => {
            const token = localStorage.getItem('token');
            console.log('token', token)
            if (!token) {
                setIsLoading(false);
                return;
            }

            try {
                await axios.post('https://clf40sww7000008laey0whwq7.xyz:3001/user/protected', {}, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }).then(res => {
                    setIsAuthenticated(res.data.isAuthenticated);
                });

            } catch (error) {
                console.error('Error:', error);
                setIsAuthenticated(false);
            }

            setIsLoading(false);
        };

        verifyToken();
    }, []);

    if (isLoading) {
        // Optionally, you can create a loading component or just return null to render nothing
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
