import React, { useEffect, useState } from 'react';
import Sheet from '../Components/Sheet';
import './css/SheetsPage.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const generateData = (rowCount, colCount) => {
    return Array.from({ length: rowCount }, () => new Array(colCount).fill(''));
};

const Sheets = () => {
    const navigate = useNavigate();
    const [activeSheet, setActiveSheet] = useState(0);

    const [newSheetName, setNewSheetName] = useState(null);
    const [description, setDescription] = useState(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const [deleteCandidateId, setDeleteCandidateId] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadResult, setUploadResult] = useState(null);

    const openUploadModal = () => setIsUploadModalOpen(true);
    const closeUploadModal = () => {
        setIsUploadModalOpen(false);
        setFile(null);
        setUploadResult(null);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const uploadFile1 = async () => {
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('https://clf40sww7000008laey0whwq7.xyz:3001/sheets/uploadData', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUploadResult(response.data);
            setFile(null); // Сброс выбранного файла после загрузки
        } catch (error) {
            console.error('Error:', error.response);
        }
    };

    const uploadFile = async () => {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('https://clf40sww7000008laey0whwq7.xyz:3001/sheets/uploadData', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setUploadResult(response.data);
            setFile(null); // Сброс выбранного файла после загрузки

            const base64String = response.data.fileContent;

            // Декодирование строки Base64 в Blob
            const blob = b64toBlob(base64String, 'application/octet-stream');
            const url = window.URL.createObjectURL(blob);

            // Создание ссылки для скачивания и инициализация загрузки
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = 'downloaded_file.txt'; // Имя файла, которое будет сохранено
            downloadLink.click();

            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error:', error.response);
        }
    };

    // Функция для преобразования строки Base64 в Blob
    function b64toBlob(base64, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(base64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    }


    const getFile = async () => {
        try {
            const response = await axios.get('https://clf40sww7000008laey0whwq7.xyz:3001/sheets/getLines', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'filename.txt');

            document.body.appendChild(link);
            link.click();

            // Очистка и удаление ссылки
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error:', error.response);
        }
    };

    const getCandy = async () => {
        try {
            const response = await axios.get('https://clf40sww7000008laey0whwq7.xyz:3001/user/candy/getCandy', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'filename.ext');

            document.body.appendChild(link);
            link.click();

            // Очистка и удаление ссылки
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error:', error.response);
        }
    };

    const openDeleteModal = (id) => {
        setDeleteCandidateId(id);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const confirmDelete = () => {
        deleteSheet(deleteCandidateId);
        closeDeleteModal();
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [sheets, setSheets] = useState([]);

    useEffect(() => {
        getSheets();
    }, []);

    const deleteSheet = async (id) => {
        await axios.delete(`https://clf40sww7000008laey0whwq7.xyz:3001/sheets/sheet/${id}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            if (response) {
                console.log(response.data)
                if (response.data.isDelete) {
                    getSheets()
                }
            }
        }).catch((error) => {
            console.error('Error:', error.response);
        });
    }

    const getSheets = async () => {
        await axios.get('https://clf40sww7000008laey0whwq7.xyz:3001/sheets/getSheets',
            {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }).then((response) => {
                setSheets(response.data)
                console.log('sheets updated')
            }).catch((error) => {
                console.error('Error:', error.response);
            });
    }

    const click = async (name, description) => {
        if (!name || !description) return;
        await axios.post('https://clf40sww7000008laey0whwq7.xyz:3001/sheets/createSheet', {
            data: generateData(500, 50),
            name, description
        }, {
            headers:
            {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            if (response) {
                if (response.data.isCreated) {
                    getSheets()
                }
            }
        }).catch((error) => {
            console.error('Error:', error.response);
        });
    }
    return (
        <div>
            <div className='add-sheet-container'>
                <button className="sheet-button" onClick={openModal}>Add Sheet</button>
                <button className="sheet-button" onClick={() => getCandy()}>Get Candy</button>
                <button className="sheet-button" onClick={openUploadModal}>Load File</button>
                <button className="sheet-button" onClick={() => getFile()}>Get File</button>
            </div>
            <div className="sheet-container">

                {sheets.map((sheet, index) => (
                    <div key={index} className="sheet-item-container">

                        <div className="sheet-item" onClick={() => navigate(`/sheet/${sheet.id}`)}>
                            {sheet.name}
                        </div>
                        <button className="delete-button" onClick={() => openDeleteModal(sheet.id)}>&times;</button>
                    </div>
                ))}
                {isDeleteModalOpen && (
                    <div className="modal-backdrop active" onClick={closeDeleteModal}>
                        <div className="modal" onClick={e => e.stopPropagation()}>
                            <div className="modal-header">
                                <span className="modal-title">Are you sure?</span>
                            </div>
                            <div className="modal-body">
                                <button className="sheet-button" onClick={confirmDelete}>Delete</button>
                                <button className="sheet-button" onClick={closeDeleteModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}

                {isUploadModalOpen && (
                    <div className="modal-backdrop active" onClick={closeUploadModal}>
                        <div className="modal" onClick={e => e.stopPropagation()}>
                            <div className="modal-header">
                                <span className="modal-title">Upload File</span>
                                <button className="modal-close-button" onClick={closeUploadModal}>&times;</button>
                            </div>
                            {uploadResult ? (
                                <div className="upload-result">
                                    <p>Common Lines: {uploadResult.commonLinesCount}</p>
                                    <p>New Unique Lines: {uploadResult.newUniqueLinesCount}</p>
                                    <button className="sheet-button" onClick={closeUploadModal}>Close</button>
                                </div>
                            ) : (
                                <>
                                    <input type="file" onChange={handleFileChange} />
                                    <button className="sheet-button" onClick={uploadFile} disabled={!file}>Upload</button>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {isModalOpen && (
                    <div className="modal-backdrop active" onClick={closeModal}>
                        <div className="modal" onClick={e => e.stopPropagation()}> {/* Предотвращаем закрытие при клике внутри модального окна */}
                            <div className="modal-header">
                                <span className="modal-title">Add New Sheet</span>
                                <button className="modal-close-button" onClick={closeModal}>&times;</button> {/* Кнопка закрыть */}
                            </div>
                            <input className="sheet-input" type="text" onChange={(event) => setNewSheetName(event.target.value)} placeholder="Sheet Name" />
                            <input className="sheet-input" type="text" onChange={(event) => setDescription(event.target.value)} placeholder="Description" />
                            <button className="sheet-button" onClick={() => {
                                click(newSheetName, description);
                                closeModal();
                            }}>Create Sheet</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Sheets;
